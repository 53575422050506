<template>
  <v-dialog v-model="isOpen" max-width="800px" persistent>
    <v-card>
      <v-card-title class="px-4 py-4 justify-space-between flex-column flex-sm-row align-sm-center" style="gap: 16px">
        <div class="d-flex align-center" style="gap: 8px;">
          <v-btn
            style="
              min-width: unset !important;
              min-height: unset !important;
              width: 32px !important;
              height: 32px !important;
            "
            class="pt-0"
            @click.stop="cerrarModal"
            text
            rounded
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
          <h5 class="text-h6 secondary--text text-uppercase font-weight-bold" style="word-break: break-word;">
            Agregar certificación
          </h5>
        </div>
        <div class="d-flex flex-column justify-center flex-wrap flex-sm-row align-md-center" style="gap: 16px;">
          <div>
              <dt class="font-weight-bold text-subtitle-2 text-uppercase">Monto requerido</dt>
              <dd class="text-body-2">{{ toMoneyFormat(montoSolicitado) }}</dd>
          </div>

          <v-divider class="d-none d-md-block" vertical />

          <div>
              <dt class="font-weight-bold text-subtitle-2 text-uppercase">Monto certificado</dt>
              <dd class="text-body-2">{{ toMoneyFormat(montoCertificado ?? 0) }}</dd>
          </div>
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text class="mt-4">
        <v-form @submit.prevent="manejarEnvioFormulario" class="mt-4">
          <v-text-field
            v-model="form.monto"
            placeholder="Ingrese el monto"
            label="Monto"
            outlined
            type="number"
            :step="0.5"
            min="0"
            :error-messages="montoErrors"
            @keypress="preventE"
            @blur="this.$v.form.monto.$touch"
            @change="this.$v.form.monto.$touch"
          />

          <v-file-input
            v-model="form.adjunto"
            label="Certificado"
            placeholder="Certificado de fondos"
            outlined
            light
            accept="application/pdf"
            :error-messages="adjuntoErrors"
            @blur="this.$v.form.adjunto.$touch"
          />

          <div
            class="d-flex justify-space-between align-center mt-4"
            style="gap: 16px"
          >
            <v-btn
              large
              color="secondary"
              class="flex-grow-1 flex-shrink-1"
              @click.stop="cerrarModal"
            >
              Cerrar
            </v-btn>
            <v-btn
              large
              type="submit"
              color="primary"
              class="flex-grow-1 flex-shrink-1"
            >
              Guardar
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { toMoneyFormat } from "@/utils/data";
import { Validator } from "@/utils/form-validation";
import { minValue } from "vuelidate/lib/validators";
import { required, maxValue } from "vuelidate/lib/validators";

const estadoInicialFormulario = {
  monto: null,
  adjunto: null,
};

export default {
  name: "ModalAgregarCertificacion",
  emits: ["on-save"],
  validations() {
    return {
      form: {
        monto: { required, minValue: minValue(0.1), maxValue: maxValue(this.montoRestanteRefinanciar) },
        adjunto: { required },
      },
    };
  },
  props: {
    isOpen: { type: Boolean },
    montoSolicitado: { type: [String, Number] },
    montoCertificado: { type: [String, Number] },
  },
  data: () => ({
    form: { ...estadoInicialFormulario },
  }),
  computed: {
    montoErrors() {
      return new Validator(this.$v.form.monto).detect().getResult();
    },
    adjuntoErrors() {
      return new Validator(this.$v.form.adjunto).detect().getResult();
    },
    montoRestanteRefinanciar() {
      if (!this.montoSolicitado && !this.montoCertificado) return 0;

      return this.montoSolicitado - this.montoCertificado;
    },
  },
  methods: {
    // UI
    toMoneyFormat,
    preventE(event) {
      if (["e", ","].includes(event.key?.toLowerCase())) event.preventDefault();
    },
    limpiarFormulario() {
      this.$v.form.$reset();
      this.form = { ...estadoInicialFormulario };
    },
    cerrarModal() {
      this.$emit("update:is-open", false);
      this.limpiarFormulario();
    },
    manejarEnvioFormulario() {
      this.$v.form.$touch();

      if (this.$v.form.$invalid) return;

      this.$emit("on-save", { ...this.form });
      this.cerrarModal();
    },
  },
};
</script>
