<template>
    <v-container>
        <v-card>
            <v-card-title>
                <h3 class="text-h6 font-weight-bold text-uppercase" style="word-break: break-word;">
                    <NavButton style="transform: translateY(-2px);" class="mr-1" />
                    Certificación de refinanciamientos
                </h3>
            </v-card-title>
            <v-divider />
            <v-card-text>
                <RetryDataLoading :loadable="refinanciamientos" :retry-handler="cargarRefinanciamientos">
                    <EmptyComponent 
                        :empty="!(listaRefinanciamientos.length > 0) && !refinanciamientos.isLoading" 
                        colored
                        text="Este proceso de compra no posee refinanciamientos a certificar."
                        class="mt-4"
                    >
                        <template v-slot:icon>
                            <v-icon style="font-size: 48px;" color="var(--v-primary-lighten3) !important">mdi-clipboard-list</v-icon>
                        </template>
                        <div>
                            <v-row>
                                <v-col cols="12" md="6" xl="4">
                                    <v-card class="px-4 py-4">
                                        <p class="mb-0 text-h5 font-weight-bold" style="color: var(--v-info-darken2)">
                                            {{ toMoneyFormat(refinanciamientos.data?.monto_requerido, refinanciamientos.isLoading) }}
                                        </p>
                                        <h6 class="text-subtitle-2" style="color: #7a7a7c">Monto requerido a refinanciar</h6>
                                    </v-card>
                                </v-col>

                                <v-col cols="12" md="6" xl="4">
                                    <v-card class="px-4 py-4">
                                        <p class="mb-0 text-h5 font-weight-bold" style="color: var(--v-info-darken2)">
                                            {{ toMoneyFormat(refinanciamientos.data?.monto_certificado, refinanciamientos.isLoading) }}
                                        </p>
                                        <h6 class="text-subtitle-2" style="color: #7a7a7c">Monto certificado</h6>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <template>
                                <h5 class="mt-12 text-subtitle-1 text-uppercase font-weight-bold" style="color: #000000; line-height: 24px;">
                                    <v-icon style="transform: translateY(-2px)">mdi-archive-plus</v-icon>
                                    Refinanciamientos de compra
                                </h5>
                                <v-divider class="mt-1" />
                                <p v-if="refinanciamientos.isLoading" class="mb-0 mt-4 pl-2 text-body-2 text-uppercase font-weight-bold">Cargando refinanciamientos...</p>

                                <v-expansion-panels v-else class="mt-4" v-model="refinanciamientosAbiertos">
                                    <v-expansion-panel v-for="refinanciamiento in listaRefinanciamientos" :key="refinanciamiento.id">
                                        <v-expansion-panel-header v-slot:default class="px-4">
                                            <div class="d-flex flex-column">
                                                <b class="text-h6 font-weight-bold">{{ refinanciamiento.correlativo }}</b>
                                                <p class="mb-0 text-subtitle-2">{{ refinanciamiento.obs.codigo }} - {{ refinanciamiento.obs.nombre }}</p>
                                            </div>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-divider />
                                            <div class="px-4 py-4">
                                                <div class="d-flex flex-column flex-wrap justify-end flex-md-row align-md-center" style="gap: 16px;">
                                                    <div>
                                                        <dt class="font-weight-bold text-subtitle-2 text-uppercase">Monto requerido</dt>
                                                        <dd class="text-body-2">{{ toMoneyFormat(refinanciamiento.monto_requerido) }}</dd>
                                                    </div>

                                                    <v-divider class="d-none d-md-block" vertical />

                                                    <div>
                                                        <dt class="font-weight-bold text-subtitle-2 text-uppercase">Monto certificado</dt>
                                                        <dd class="text-body-2">{{ toMoneyFormat(refinanciamiento.monto_certificado) }}</dd>
                                                    </div>
                                                </div>

                                                <!-- Certificaciones por cifrado presupuestario -->
                                                <v-expansion-panels class="mt-4 px-0">
                                                    <v-expansion-panel v-slot:default v-for="fondos in refinanciamiento.fondos_refinanciamiento" :key="fondos.id"> 
                                                        <v-expansion-panel-header>
                                                            <div class="d-flex flex-column justify-center">
                                                                <span class="text-body-2 text-uppercase font-weight-bold">
                                                                    {{ `${fondos.financiamiento_unidad.fondo.cifrado_presupuestario} - ${fondos.financiamiento_unidad.fondo.tipo_fondo === 2 ? 'Votado' : 'Proyecto'}` }}
                                                                </span>
                                                                <span class="text-caption text-uppercase font-weight-medium">
                                                                    {{ toMoneyFormat(fondos.monto_certificado) }} certificados de {{ toMoneyFormat(fondos.monto_solicitado) }}
                                                                </span>
                                                            </div>
                                                            <div class="d-flex flex-column flex-wrap justify-end flex-md-row align-md-center" style="gap: 16px;">
                                                                <v-tooltip v-if="verificarSiRefinanciamientoTotalmenteCertificado(fondos)" top>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-icon 
                                                                            v-on="on" 
                                                                            v-bind="attrs"
                                                                            color="success"
                                                                            class="px-2 py-2"
                                                                        >
                                                                            mdi-certificate
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>Certificación realizada</span>
                                                                </v-tooltip>
                                                                <v-tooltip v-else top>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn 
                                                                            v-on="on" 
                                                                            v-bind="attrs" 
                                                                            class="px-2 py-2"
                                                                            icon
                                                                            @click.stop="abrirModalAgregarFinanciamiento(fondos)"
                                                                            :disabled="fondos.financiamiento_unidad.fondo.tipo_fondo !== 2"
                                                                        >
                                                                            <v-icon color="primary">mdi-certificate</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Certificar</span>
                                                                </v-tooltip>
                                                            </div>
                                                        </v-expansion-panel-header>
                                                        <!-- Cifrados -->
                                                        <v-expansion-panel-content class="px-0 py-0">
                                                            <v-divider />
                                                            <div class="px-4 py-4">
                                                                <v-expansion-panels>
                                                                    <v-expansion-panel>
                                                                        <v-expansion-panel-header>
                                                                            <div class="d-flex align-center" style="gap: 8px;">
                                                                                <v-icon>mdi-account-group</v-icon>
                                                                                <span class="text-uppercase text-uppercase text-caption font-weight-medium">
                                                                                    {{ fondos.financiamiento_unidad.unidad.nombre }}
                                                                                </span>
                                                                            </div>
                                                                        </v-expansion-panel-header>
        
                                                                        <!-- Tabla con las certificaciones -->
                                                                        <v-expansion-panel-content>
                                                                            <v-divider />
                                                                            <DataTableComponent 
                                                                                    :headers="tableHeaders"
                                                                                    :items="fondos.certificaciones"
                                                                                    :tiene_paginacion="false"
                                                                                    class="mx-4 my-4"
                                                                                    no-gutters
                                                                                    dense
                                                                                >
                                                                                    <template v-slot:item.monto_certificado="{ item }">
                                                                                        ${{ item.monto }}
                                                                                    </template>
                                                                                                                                    
                                                                                    <template v-slot:item.acciones="{ item }">
                                                                                        <div class="d-flex align-center justify-center py-2" style="gap: 8px;">
                                                                                            <template>
                                                                                                <v-tooltip top>
                                                                                                    <template v-slot:activator="{ on, attrs }">
                                                                                                        <v-btn 
                                                                                                            v-on="on" 
                                                                                                            v-bind="attrs" 
                                                                                                            :loading="visualizacionDocumento.isLoading"
                                                                                                            class="px-5 py-5" 
                                                                                                            icon 
                                                                                                            @click.stop="visualizarDocumentoCertificacion(item)"
                                                                                                        >
                                                                                                            <v-icon>mdi-file-eye</v-icon>
                                                                                                        </v-btn>
                                                                                                    </template>
                                                                                                    <span>Ver documento certificación</span>
                                                                                                </v-tooltip>
                                                                                            </template>
                                                                                        </div>
                                                                                    </template>
                                                                            </DataTableComponent>
                                                                        </v-expansion-panel-content>
                                                                    </v-expansion-panel>
                                                                </v-expansion-panels>
                                                            </div>
                                                        </v-expansion-panel-content>
                                                    </v-expansion-panel>
                                                </v-expansion-panels>
                                            </div>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </template>
                        </div>
                    </EmptyComponent>
                </RetryDataLoading>
            </v-card-text>
        </v-card>
        <ModalAgregarCertificacion 
            :is-open.sync="modalAgregarCertificacionAbierta"
            :monto-certificado="fondoFinanciamientoActivo?.monto_certificado"
            :monto-solicitado="fondoFinanciamientoActivo?.monto_solicitado"
            @on-save="confirmarCreacionCertificacion"
        />
        
        <ConfirmationModalComponent
            :is-open="confirmacionCrearCertificacion"
            :is-loading="creacionCertificacion.isLoading"
            description="¿Está seguro de certificar este fondo?"
            @cancel="confirmacionCrearCertificacion = false"
            @confirm="crearCertificacion"
        />

        <ConfirmationModalComponent
            :is-open="confirmacionEliminadoCertificacion"
            :is-loading="eliminadoCertificacion.isLoading"
            description="¿Está seguro de eliminar la certificación del fondo?"
            @cancel="confirmacionEliminadoCertificacion = false"
            @confirm="eliminarCertificacion"
        />

        <PdfModal
            :isOpen="modalVisualizacionAbierta" 
            :source-loadable="visualizacionDocumento" 
            @on-visibility-change="manejarEstadoModalVisualizacion"
            filename="documento-certificacion"
        />
    </v-container>
</template>
<script>
import { createLoadable, setLoadableResponse, toggleLoadable, isResponseSuccesful } from '@/utils/loadable';
import ModalAgregarCertificacion from './components/ModalAgregarCertificacion.vue';
import { toMoneyFormat, toFixed, convertToFormData } from '@/utils/data';
import { EmptyComponent, RetryDataLoading } from '@/components/utils';
import DataTableComponent from '@/components/DataTableComponent.vue';
import { ConfirmationModalComponent } from '@/components/utils';
import NavButton from '@/components/utils/NavButton.vue';
import PdfModal from '@/components/PdfModal.vue';

export default {
    name: 'certificarRefinanciamientos',
    components: { 
        EmptyComponent, 
        RetryDataLoading, 
        NavButton, 
        ConfirmationModalComponent, 
        DataTableComponent, 
        PdfModal,
        ModalAgregarCertificacion,
    },
    data: () => ({
        idProcesoCompra: null,
        refinanciamientos: createLoadable(),
        refinanciamientosAbiertos: null,
        // Acciones
        modalAgregarCertificacionAbierta: false,
        fondoFinanciamientoActivo: null,
        // Creacion certificacion
        informacionCreacionCertificacion: null,
        confirmacionCrearCertificacion: false,
        creacionCertificacion: createLoadable(null),
        // Visualizacion documento de certificacion
        modalVisualizacionAbierta: false,
        visualizacionDocumento: createLoadable(null),
        // Eliminado de certificacion
        confirmacionEliminadoCertificacion: false,
        eliminadoCertificacion: createLoadable(null),
    }),
    computed: {
        listaRefinanciamientos() {
            return this.refinanciamientos.data?.refinanciamientos ?? [];
        },
        tableHeaders() {
            return [
                { text: 'Monto certificado', value: 'monto_certificado', align: 'center', sortable: false },
                { text: 'Acciones', value: 'acciones', align: 'center', sortable: false },
            ];
        },
    },
    methods: {
        toMoneyFormat,
        abrirModalAgregarFinanciamiento(fondoRefinanciamiento) {
            this.modalAgregarCertificacionAbierta = true;
            this.fondoFinanciamientoActivo = { ...fondoRefinanciamiento };
        },
        confirmarCreacionCertificacion(informacion) {
            this.informacionCreacionCertificacion = { ...informacion };
            this.confirmacionCrearCertificacion = true;
        },
        manejarEstadoModalVisualizacion(visible) {
            this.modalVisualizacionAbierta = visible;
        },
        confirmarEliminadoCertificacion(fondoRefinanciamiento) {
            this.fondoFinanciamientoActivo = { ...fondoRefinanciamiento };
            this.confirmacionEliminadoCertificacion = true;
        },
        verificarSiRefinanciamientoTotalmenteCertificado(refinanciamiento) {
            return !(refinanciamiento.monto_solicitado - refinanciamiento.monto_certificado > 0);
        },
        // Data
        async cargarRefinanciamientos() {
            toggleLoadable(this.refinanciamientos);
            const { data } = await this.services.CertificacionRefinanciamientoCompras.cargarCifradosCertificaciones(this.idProcesoCompra);

            setLoadableResponse(this.refinanciamientos, data);
        },
        async crearCertificacion() {
            toggleLoadable(this.creacionCertificacion);

            const formData = convertToFormData(this.informacionCreacionCertificacion);
            const { data } = await this.services.CertificacionRefinanciamientoCompras.crearCertificacion(
                this.idProcesoCompra,
                this.fondoFinanciamientoActivo.id,
                formData,
            );

            this.confirmacionCrearCertificacion = false;
            setLoadableResponse(this.creacionCertificacion, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) this.cargarRefinanciamientos();

            if (!isResponseSuccesful(data) && data.error.code === 'RELOAD_REQUIRED') this.cargarRefinanciamientos();
        },
        async visualizarDocumentoCertificacion(certificacion) {
            this.modalVisualizacionAbierta = true;
            toggleLoadable(this.visualizacionDocumento);
            const { data } = await this.services.CertificacionRefinanciamientoCompras.visualizarDocumentoCertificacion(
                this.idProcesoCompra,
                certificacion.id,
            );
            setLoadableResponse(this.visualizacionDocumento, data, { isFile: true });
        },
        async eliminarCertificacion() {
            toggleLoadable(this.eliminadoCertificacion);
            const { data } = await this.services.CertificacionRefinanciamientoCompras.eliminarCertificacion(
                this.idProcesoCompra,
                this.fondoFinanciamientoActivo.certificacion.id,
            );
            this.confirmacionEliminadoCertificacion = false;

            setLoadableResponse(this.eliminadoCertificacion, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) this.cargarRefinanciamientos();
        },
    },
    created() {
        this.idProcesoCompra = this.$route.params.id_proceso_compra;
        this.cargarRefinanciamientos();
    },
}
</script>
<style scoped>
:deep(.v-expansion-panel-content .v-expansion-panel-content__wrap) {
    padding: 0 !important;
}
</style>